<template>
  <OpenTime v-if="show" />
</template>

<script>
import { mapGetters } from 'vuex';
export default {
  components: {
    OpenTime: () => import('@components/open-time/OpenTime.vue'),
  },
  computed: {
    ...mapGetters('delivery', ['isWarehouseOpen', 'warehouse']),
    show() {
      return this.warehouse && !this.isWarehouseOpen;
    },
  },
};
</script>

<style></style>
