<!-- eslint-disable vue/attribute-hyphenation -->
<template>
  <div class="">
    <p class="font-bold text-lg md:text-2xl">
      {{ $t('orderDetails') }}
    </p>
    <div class="mt-4 md:mt-7 pb-1">
      <div
        v-for="(detail, index) in details"
        :key="index"
        class="mb-4 last:mb-0 flex items-start text-sirius-gray-700 font-medium text-sm"
      >
        <UiInlineIcon
          :name="`order-${detail.icon}`"
          width="20"
          height="20"
          :viewBox="
            ['bike', 'loyalty-order'].includes(detail.icon)
              ? '0 0 20 20'
              : '0 0 24 24'
          "
        />
        <div class="ml-3">
          <p v-if="detail.subtext" class="text-sm text-sirius-gray-100">
            {{ detail.subtext }}
          </p>
          <div class="flex items-center">
            <span
              :class="{
                'text-sirius-orange-100': detail.icon === 'loyalty-order',
              }"
            >
              {{ detail.text }}
            </span>
            <InfoTooltip
              v-if="detail.tooltipMsg"
              class="px-1"
              :position="detail.tooltipPos"
              @click.native.stop
            >
              <template #tip>
                <div
                  class="text-xs md:text-sm font-medium leading-14 md:leading-4"
                  v-html="detail.tooltipMsg"
                ></div>
              </template>
            </InfoTooltip>
          </div>
        </div>
      </div>
    </div>

    <div class="flex flex-col md:flex-row md:items-center mt-7">
      <UiButton class="w-full" variant="gray" size="lg" @click="fetchInvoice">
        <div class="text-left w-full flex items-center justify-between">
          {{ $t('invoice') }}
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M15 4.5L22.5 12L15 19.5"
              stroke="black"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </div>
      </UiButton>
    </div>
    <div v-if="billSrc" class="flex flex-col mt-4">
      <UiButton class="w-full" size="lg" variant="gray" @click="openBillSrc">
        <div class="text-left w-full flex items-center justify-between">
          {{ $t('purchaseReceipt') }}
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M15 4.5L22.5 12L15 19.5"
              stroke="black"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </div>
      </UiButton>
    </div>

    <BottomSheetPopup name="note" style="--modal-width: 577px">
      <UiRippleLoader
        class="absolute left-1/2 transform -translate-x-1/2 z-0"
      />
      <iframe
        :src="noteSrc"
        class="relative w-full h-screen box-border z-block"
      ></iframe>
    </BottomSheetPopup>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { api } from '@shared/services/api';
import { RahmetApp } from '@shared/RahmetApp';
import { DevConfigs } from '@/utils/dev-config';
import { CashbackService } from '@services/cashback';
import { dateAndTime } from '@/utils/formatters/date';
import { ORDER_STATUSES } from '@services/orders/constants';
import { rFetchInvoiceHash } from '@shared/services/api/requestsV2';
import { stringToMD5 } from '@/utils/functions/string-to-md5';
import { wordDeclination } from '@shared/utils/delivery/word-declination';

import BottomSheetPopup from '@shared/components/ui/bottom-sheet-popup/BottomSheetPopup.vue';
import UiInlineIcon from '@pure-ui/components/UiInlineIcon/UiInlineIcon.vue';
import InfoTooltip from '@components/info-tooltip/InfoTooltip.vue';
import UiRippleLoader from '@ui/UiRippleLoader.vue';
import UiButton from '@ui/UiButtonNew.vue';
import { getOrderLoyaltyCount } from '@/lib/loyalty/get-order-loyalty-count';

export default {
  name: 'OrderDetails',
  components: {
    BottomSheetPopup,
    UiRippleLoader,
    UiInlineIcon,
    InfoTooltip,
    UiButton,
  },
  inject: ['bottomSheetPopup', 'toast'],
  props: {
    order: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    billSrc: null,
    noteSrc: '',
    fetchBillReciept: api.ofd.orderBill,
    nyContestPoints: 0,
  }),
  computed: {
    ...mapGetters('delivery', ['expectedDeliveryTime']),
    ...mapGetters('user', ['userProfile']),
    ...mapGetters('cashbacks', [
      'activeCashbacksCondition',
      'hasCashbacksProgram',
    ]),
    paidAt() {
      return this.order.paid_at ? dateAndTime(this.order.paid_at) : '';
    },
    showButtons() {
      return (
        this.order &&
        (this.order.status === ORDER_STATUSES.DELIVERED ||
          this.order.status === ORDER_STATUSES.FINISHED)
      );
    },
    isFinished() {
      const finishedStatuses = [
        ORDER_STATUSES.DELIVERED,
        ORDER_STATUSES.FINISHED,
        ORDER_STATUSES.CANCELED,
      ];
      return this.order && finishedStatuses.includes(this.order.status);
    },
    details() {
      const details = [];
      if (this.order.address) {
        details.push({
          icon: 'location',
          text: this.order.address,
        });
      }

      if (!this.isFinished && this.expectedDeliveryTime) {
        const time = this.expectedDeliveryTime;
        details.push({
          icon: 'time',
          text: `${time} ${this.declineTime(time)}`,
          tooltipMsg: this.order.is_distant
            ? this.$t('isDistantMsg', { time })
            : '',
        });
      }

      if (this.order.paid_at) {
        let msg = this.$t('free');

        if (this.order.delivery_price !== 0) {
          msg = this.$t('paid', { price: this.order.delivery_price });
        }

        if (this.order.promocode?.free_delivery) {
          msg = this.$t('freeWithPromocode', [this.order.promocode.code]);
        }

        details.push({
          icon: 'bike',
          text: msg,
          tooltipMsg:
            this.order.delivery_price && this.order.high_delivery_price
              ? this.$t('highPriceMsg')
              : '',
        });
      }

      const loyaltyCount = getOrderLoyaltyCount(this.order);
      if (
        this.$isWebView &&
        this.$store.getters['loyalty/available'] &&
        loyaltyCount
      ) {
        details.push({
          icon: 'loyalty-order',
          text: '+ ' + this.$tc('loyalty', loyaltyCount),
        });
      }

      if (this.nyContestPoints) {
        details.push({
          icon: 'ny-contest',
          text: this.$t('scoreInNyContest', {
            scores: this.$tc('scoresPlural', this.nyContestPoints),
          }),
        });
      }

      if (this.hasCashbacksProgram) {
        const { price } = this.order;
        const condition = this.activeCashbacksCondition;
        const cashback = CashbackService.calculateCashback(price, condition);

        details.push({
          icon: 'cashback',
          text: this.$t('cashbacks', [cashback]),
          tooltipMsg: this.$t('cashbacksMsg'),
          tooltipPos: 'bottom',
        });
      }

      // если применён обычный промокод на скидку
      const { promocode } = this.order;
      if (promocode && !promocode.free_delivery) {
        const label =
          promocode.type === 'percentage'
            ? 'promocodeWithPercent'
            : 'promocodeWithDiscount';
        details.push({
          icon: 'promocode',
          text: this.$t(label, [promocode.code, promocode.amount]),
        });
      }

      if (this.order.service_fee) {
        details.push({
          icon: 'service-fee',
          text: this.$t(`serviceFee`, { price: this.order.service_fee }),
        });
      }

      if (this.order.items.find(e => e.product_id === 11700)) {
        details.push({
          icon: 'appliances',
          text: this.$t('addAppliances'),
        });
      }
      if (this.order.less_packages) {
        details.push({
          icon: 'lesspackages',
          text: this.$t('useLessPackages'),
        });
      }
      if (this.order.leave_at_the_door) {
        details.push({
          icon: 'contactlessdelivery',
          text: this.$t('leaveAtTheDoor'),
        });
      }
      if (this.order.comment) {
        details.push({
          icon: 'comments',
          text: this.order.comment,
          subtext: this.$t('orderComments'),
        });
      }

      return details.length !== 0 ? details : null;
    },
  },
  watch: {
    'order.status': function onStateChange(newValue) {
      if (newValue === 7 || newValue === 10) {
        this.fetchOrderBill();
      }
    },
  },
  mounted() {
    this.fetchOrderBill();
    this.fetchNyContestScore();
  },
  methods: {
    sendToMail() {},
    fetchInvoice() {
      rFetchInvoiceHash(this.order.id)
        .then(hash => {
          const invoiceURL = `${import.meta.env.VITE_API_PROXY}/lavka/v1/invoice/${this.order.id}?hash=${hash}`;
          this.noteSrc = `https://docs.google.com/viewer?embedded=true&url=${invoiceURL}`;
          this.bottomSheetPopup.show('note');
        })
        .catch(err => {
          this.toast('Не удолось получить', 'error');
          throw err;
        });
    },
    /** '13-17' */
    declineTime(time) {
      let [minut1, minut] = time.split('-');
      minut = minut || minut1 || 17;
      if (this.$i18n.locale === 'kk') return 'минут';
      return minut && wordDeclination(minut, ['минута', 'минуты', 'минут']);
    },
    openBillSrc() {
      if (this.$isWebView) {
        RahmetApp.openLink({ url: this.billSrc });
        return;
      }
      window.open(this.billSrc, '_blank');
    },
    fetchOrderBill() {
      if (!this.showButtons || !this.userProfile) return;
      const { id } = this.order;
      const hash = stringToMD5(
        `${this.userProfile.user_id}${this.order.id}$%naruto!@`
      );
      if (!DevConfigs.isDev)
        this.fetchBillReciept(id, hash)
          .then(res => {
            const url =
              res.data.attributes.responsePayload.TicketPrintUrl || null;
            if (url) this.billSrc = url;
          })
          .catch(() => {});
    },
    fetchNyContestScore() {
      api.lavka.fetchNYCompetitionOrderPoints(this.order.id).then(data => {
        const point = data.data?.point;
        if (point) {
          this.nyContestPoints = point;
        }
      });
    },
  },
  i18n: {
    messages: {
      ru: {
        orderDetails: 'Детали заказа',
        free: 'Бесплатно',
        freeWithPromocode: 'Бесплатно, промокод {0}',
        paid: '{price} ₸',
        invoice: 'Накладная',
        toSendToEmail: 'Отправить на почту',
        purchaseReceipt: 'Чек о покупке',
        addAppliances: 'Добавить приборы',
        serviceFee: 'Сервисный сбор: {price} ₸',
        promocodeWithDiscount: 'Промокод {0} на {1} ₸',
        promocodeWithPercent: 'Промокод {0} на {1}%',
        highPriceMsg:
          'Увеличенная стоимость доставки из-за плохих погодных условий.',
        isDistantMsg: `Доставка за {time} минут, адрес находится в&nbsp;расширенной зоне`,
        cashbacks: '+ {0} бонусов кешбэка',
        cashbacksMsg:
          'Баланс кешбэка отображается на главном экране Choco, а также при оплате.',
        scoresPlural: '0 баллов | {n} балл | {n} балла | {n} баллов',
        scoreInNyContest: '{scores} в розыгрыше «Дед Мороз Рядом»',
        useLessPackages: 'Использовать как можно меньше пакетов',
        leaveAtTheDoor: 'Оставить заказ у двери',
        orderComments: 'Комментарий к заказу',
        loyalty: '0 бонусов | {n} бонус | {n} бонуса | {n} бонусов',
      },
      kk: {
        orderDetails: 'Тапсырыс мәліметтері',
        free: 'Тегін',
        freeWithPromocode: 'Тегін, промокод {0}',
        paid: '{price} ₸',
        invoice: 'Шот-фактура',
        toSendToEmail: 'Поштаға жіберу',
        purchaseReceipt: 'Сатып алу түбіртегі',
        addAppliances: 'Асхана құралдарын қосу',
        serviceFee: 'Қызмет ақысы: {price} ₸',
        promocodeWithDiscount: '{1} ₸-ге {0} промокоды',
        promocodeWithPercent: '{1}%-ға {0} промокоды',
        highPriceMsg: 'Ауа-райы себепті тапсырыс бағасы қымбаттады',
        isDistantMsg: `Жеткізу {time} минут алады, мекенжай кеңейтілген алаңда орналасқан`,
        cashbacks: '+ {0} кешбэк бонустары',
        cashbacksMsg:
          'Кешбэк балансы Choco-ның басты бетінде және төлем алынар алдында көрсетіледі.',
        scoresPlural: '{n} ұпай',
        scoreInNyContest: ' «Рядом Аяз Ата» ұтыс ойнында {scores}',
        useLessPackages: 'Азырақ пакет қолдану',
        leaveAtTheDoor: 'Тапсырысты есік алдында қалдыру',
        orderComments: 'Тапсырысқа қосымша хат',
        loyalty: '{n} бонус',
      },
    },
  },
};
</script>
