import dayjs from 'dayjs';

/**
 * Так как вся лояльность реализована на фронте,
 * предполагаем, что ТОЛЬКО на заказы, оформленные с этой даты
 * начислен кешбек лояльности
 */
const LOYALTY_START = '2024-09-01';

export function getOrderLoyaltyCount(order = {}) {
  const { status, price = 0, delivery_price = 0, service_fee = 0 } = order;

  if (dayjs(order.paid_at).isBefore(LOYALTY_START)) return 0;

  if (![7, 9, 10].includes(status)) return 0;

  return parseInt(`${(price + delivery_price + service_fee) * 0.01}`);
}
