<template>
  <RenderlessAddressCreation
    ref="AddressCreationRef"
    v-slot="{ submit, inputCoords, state }"
    @onEditLoading="v => (onEditAddressLoading = v)"
  >
    <div class="AddressView">
      <!-- Кнопка назад -->
      <UiInlineIcon
        name="circle-back-btn"
        class="back-btn"
        @click.native="onBackClick"
      />

      <!-- v-if="id === 'create' || (id !== 'create' && inputCoords.length)" -->
      <AddressMap
        class="h-full"
        :edit="id !== 'create'"
        :input-coords="inputCoords"
        @actionBegin="onMapAction"
        @onActionEnd="onFormFocused"
        @onContinue="onContinue"
        @onUpdateGeoObject="onUpdateGeoObject"
        @onEditLoading="v => (onEditMapLoading = v)"
      />

      <!-- Форма ввода адреса -->
      <AddressFormModal
        v-model="addressFormModal"
        :state="state"
        :address="
          onEditAddressLoading || onEditMapLoading
            ? ''
            : streetTitle || state.street
        "
        :loading="onEditAddressLoading || onEditMapLoading"
        @onFillAll="onFillAll"
        @onMaxLength="onMaxLength"
        @onSubmit="v => onSubmit(v, submit)"
        @onGoBack="onFormGoBack"
      />

      <SameAsSavedAddressModal
        v-if="sameAsSavedAddress"
        :model-value="!!sameAsSavedAddress"
        :title="sameAsSavedAddress.name"
        :address="sameAsSavedAddress.street"
        @onChooseThis="onSelectSaved"
        @onChooseOther="onChooseOther"
      />
    </div>
  </RenderlessAddressCreation>
</template>

<script>
import { AddressService } from '@services/address';

import AddressFormModal from '@pure-ui/components/Address/AddressFormModal/AddressFormModal.vue';
import RenderlessAddressCreation from '@components/address/RenderlessAddressCreation';
import UiInlineIcon from '@pure-ui/components/UiInlineIcon/UiInlineIcon.vue';

import AddressMap from './components/AddressMap.vue';

export default {
  name: 'AddressView',
  components: {
    SameAsSavedAddressModal: () =>
      import(
        '@pure-ui/components/Address/SameAsSavedAddressModal/SameAsSavedAddressModal.vue'
      ),
    RenderlessAddressCreation,
    AddressFormModal,
    UiInlineIcon,
    AddressMap,
  },
  inject: ['toast'],

  props: {
    id: {
      type: String,
      required: true,
    },
  },
  data: () => ({
    addressFormModal: false,

    sameAsSavedAddress: null,
    isExtendedDelivery: false,
    mapFocused: false,
    /**
     * Хранит валидный адрес, выбранный на карте
     * т. е. имеющий улицу, дом и находящийся в пределах города.
     * Может не входить в зоны доставки — тогда показывается.
     * Такой адрес всё равно можно сохранить, но будет
     * показана модалка "сюда пока не доставляем"
     */
    GeoObject: null,
    loading: true,
    streetTitle: '',

    onEditMapLoading: false,
    onEditAddressLoading: false,
  }),
  mounted() {
    if (this.id !== 'create') this.openFormModal();
    this.$refs.AddressCreationRef.init(this.id);
  },
  methods: {
    onFormGoBack(toMap) {
      if (toMap) return;
      if (this.id !== 'create') this.onBackClick();
    },
    openFormModal() {
      this.addressFormModal = true;
    },
    closeFormModal() {
      this.addressFormModal = false;
    },
    onMapAction() {
      this.mapFocused = true;
    },
    onFormFocused() {
      this.mapFocused = false;
    },
    resetSavedAddress() {
      setTimeout(() => {
        this.sameAsSavedAddress = null;
      }, 100);
    },
    onSubmit(form, submitCB) {
      this.$log('onSubmit', { id: this.id, GeoObject: this.GeoObject, form });
      submitCB(
        form,
        this.GeoObject,
        this.id,
        this.isExtendedDelivery,
        'mobile_map'
      ).then(res => {
        if (res?.savedAddress) {
          this.sameAsSavedAddress = res.savedAddress;
        } else {
          this.closeFormModal();
          this.$router.go(-1);
        }
      });
    },
    onUpdateGeoObject({ GeoObject, streetTitle, isExtended }) {
      this.$log('onUpdateGeoObject', { GeoObject, streetTitle, isExtended });
      // this.streetTitle = streetTitle; // убрали при редактирований
      this.GeoObject = GeoObject;
      this.isExtendedDelivery = isExtended;
    },
    onContinue({ GeoObject, streetTitle, isExtended }) {
      this.$log('onContinue', { streetTitle, GeoObject });
      this.streetTitle = streetTitle;
      this.GeoObject = GeoObject;
      this.isExtendedDelivery = isExtended;
      this.resetSavedAddress();
      this.openFormModal();
    },
    onSelectSaved() {
      AddressService.changeAddress(this.sameAsSavedAddress).finally(() => {
        this.closeFormModal();
        this.$router.go(-1);
        this.resetSavedAddress();
      });
    },
    onChooseOther() {
      this.resetSavedAddress();
    },
    onBackClick() {
      this.closeFormModal();
      this.$router.go(-1);
    },
    onFillAll() {
      this.toast.show(this.$t('fillAll'), 'error');
    },
    onMaxLength() {
      this.toast.show(this.$t('maxLength'), 'error');
    },
  },
  i18n: {
    messages: {
      ru: {
        fillAll: `<span class="font-bold">Заполните обязательные поля:</span><br/>
          <span class="text-sm font-normal">квартира, подъезд и этаж</span>`,
        maxLength: `<span class="font-bold">Название слишком длинное</span><br/>
        <span class="font-normal text-sm">Сократите название для адреса до 25 символов</span>`,
        addressSelected: 'Адрес выбран основным',
      },
      kk: {
        fillAll: `<span class="font-bold">Барлық міндетті жолақтарды толтырыңыз:</span><br/>
          <span class="text-sm font-normal">пәтер, кіреберіс ж/ә қабат</span>`,
        maxLength: `<span class="font-bold">Атауы тым ұзын</span><br/>
        <span class="font-normal text-sm">Атауын 25 таңбаға дейін қысқартыңыз</span>`,
        addressSelected: 'Мекенжай басты болып таңдалды',
      },
    },
  },
};
</script>

<style scoped>
.AddressView {
  @apply max-h-screen h-full pb-28;
  /* height: calc(100vh - 112px); */
}
.back-btn {
  @apply fixed z-20 top-5 left-4;
}
</style>
