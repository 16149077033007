<template>
  <HeaderBaseWrapper hideable>
    <template #header-top>
      <HeaderBase>
        <template #left>
          <IconSupport class="header-icon" @click.native="$emit('onSupport')" />
        </template>
        <AddressContext class="header-center" />
        <template #right>
          <IconClose class="header-icon" @click.native="$emit('onClose')" />
        </template>
      </HeaderBase>
    </template>
    <template #header-middle>
      <SearchTrigger disabled @click.native="$emit('onSearch')" />
      <IconLoyalty
        v-if="$store.getters['loyalty/available']"
        :count="$store.getters['loyalty/count']"
        @click.native="$emit('onLoyalty')"
      />
      <IconFavorite v-else @click.native="$emit('onFavorite')" />
    </template>
  </HeaderBaseWrapper>
</template>

<script>
import SearchTrigger from '@pure-ui/components/SearchTrigger/SearchTrigger.vue';
import AddressContext from '@components/address-context/AddressContext.vue';
import HeaderBaseWrapper from './components/HeaderBaseWrapper.vue';
import IconSupport from './components/IconSupport.vue';
import HeaderBase from './components/HeaderBase.vue';
import IconClose from './components/IconClose.vue';
import IconLoyalty from './components/IconLoyalty.vue';
import IconFavorite from './components/IconFavorite.vue';

export default {
  components: {
    HeaderBaseWrapper,
    AddressContext,
    SearchTrigger,
    IconSupport,
    HeaderBase,
    IconClose,
    IconLoyalty,
    IconFavorite,
  },
};
</script>

<style scoped>
.header-icon {
  @apply my-1;
}
.header-center {
  padding-top: 8px;
}
</style>
