<template>
  <component
    :is="comp"
    :time="expectedDeliveryTime"
    class="AddressContext"
    :no-delivery="noDelivery"
    @click.native="onAddressClick"
  >
    <template #address>{{ addressTitle }}</template>
    <template #time>{{ timeTitle }}</template>
  </component>
</template>

<script>
import { mapGetters } from 'vuex';
import { RahmetApp } from '@shared/RahmetApp';
import { getAddressText } from '@services/address/lib/get-address-text';

import CartAddressContext from './CartAddressContext.vue';
import MainAddressContext from './MainAddressContext.vue';

export default {
  props: {
    isCart: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapGetters('delivery', ['expectedDeliveryTime', 'warehouse']),
    ...mapGetters('user', [
      'deliveryAddress',
      'addressCount',
      'isAddressLoading',
    ]),
    comp() {
      return this.isCart ? CartAddressContext : MainAddressContext;
    },
    addressTitle() {
      return (
        getAddressText(this.deliveryAddress, {
          lang: this.$i18n.locale,
          withName: true,
        }) || this.$t('provideAddress')
      );
    },
    noDelivery() {
      return !this.isAddressLoading && this.deliveryAddress && !this.warehouse;
    },
    timeTitle() {
      if (this.noDelivery) return this.$t('noDelivery');
      return this.addressCount === 0
        ? this.$t('deliveryTimeFrom15')
        : this.$t('deliveryTime', [this.expectedDeliveryTime]);
    },
  },
  mounted() {
    this.controllDeeplink();
  },
  methods: {
    openAddressList() {
      this.$eventBus.emit('eb_open_address_list', {
        from: this.isCart ? 'cart_mobile_header' : 'main_mobile_header',
      });
    },
    goCreateAddress() {
      this.$eventBus.emit('eb_open_address_create_container', {
        from: this.isCart ? 'cart_mobile_header' : 'main_mobile_header',
      });
    },
    onAddressClick() {
      RahmetApp.hapticSelection();
      this.addressCount !== 0 ? this.openAddressList() : this.goCreateAddress();
    },
    controllDeeplink() {
      if (this.$route.params.changeAddress) this.openAddressList();
    },
  },
  i18n: {
    messages: {
      ru: {
        provideAddress: 'Укажите адрес доставки',
        deliveryTime: 'Доставим за {0} минут',
        deliveryTimeFrom15: 'доставка от 15 минут',
        noDelivery: 'сюда пока не доставляем',
      },
      kk: {
        provideAddress: 'Жеткізу мекенжайын таңдаңыз',
        deliveryTime: '{0} минутта жеткіземіз',
        deliveryTimeFrom15: 'жеткізу 15 минуттан',
        noDelivery: 'бұл жерге әзір жеткізбейміз',
      },
    },
  },
};
</script>

<style scoped></style>
