<template>
  <div class="">
    <div
      v-for="(item, index) in chips"
      :key="index"
      class="last:border-r-8 border-transparent"
    >
      <!-- !item[disabled] ? 'text-sirius-gray-100 bg-sirius-gray-200' : -->
      <div
        class="ui-chips flex items-center px-2 mr-2 text-center transition duration-200 whitespace-nowrap cursor-pointer"
        :class="[
          isSelected(item)
            ? 'bg-sirius-orange-100 text-white'
            : `${variants[variant]} text-sirius-black-100`,
          chipsItemClass,
          sizes[size],
        ]"
        @click="selectChip(item[valueId])"
      >
        {{ getCategoryName(item[text]) }}
      </div>
    </div>
  </div>
</template>

<script>
import { removeCityFromString } from '@services/city/utils/remove-city-from-string';
const SIZES = {
  xs: '--xs',
  sm: '--sm',
  md: '--md',
  lg: '--lg',
  xl: '--xl',
};
const VARIANTS = {
  gray: '--gray',
  'gray-light': '--gray-light',
};
export default {
  name: 'UiChips',
  props: {
    value: {
      type: Array,
      default: () => [],
      validator: e => {
        if (Array.isArray(e)) return e;
        return [];
      },
    },
    chips: {
      type: Array,
      default: () => [],
    },
    valueId: {
      type: String,
      default: 'id',
    },
    text: {
      type: String,
      default: 'title',
    },
    chipsItemClass: {
      type: String,
      default: '',
    },
    disabled: {
      type: String,
      default: '',
    },
    size: {
      type: String,
      default: 'md',
      validator: v => SIZES.hasOwnProperty(v),
    },
    variant: {
      type: String,
      default: 'gray',
      validator: v => VARIANTS.hasOwnProperty(v),
    },
  },
  data: () => ({
    sizes: SIZES,
    variants: VARIANTS,
  }),
  methods: {
    getCategoryName(name) {
      return removeCityFromString(`${name}`);
    },
    isSelected(item) {
      if (!this.value) return false;
      if (!Array.isArray(this.value)) return false;
      return this.value.includes(item[this.valueId]);
    },
    selectChip(index) {
      const newVal = [];

      const foundIdx = this.value.findIndex(e => e === index);

      if (foundIdx === -1) {
        newVal.push(...this.value, this.chips.find(v => v.id === index).id);
      } else {
        newVal.push(...this.value.filter(v => v !== index));
      }

      const emitData = {
        action: foundIdx === -1 ? 'select' : 'deselect',
        categoryId: this.chips.find(v => v.id === index).id,
        categoryName: this.chips.find(v => v.id === index).name,
        tags: newVal,
      };

      this.$emit('onChange', emitData);
    },
  },
};
</script>

<style lang="postcss">
.ui-chips {
  &.--md {
    @apply rounded-lg h-7;
  }

  &.--lg {
    @apply rounded-xl h-9;
  }

  &.--gray {
    @apply bg-sirius-gray-600;
  }

  &.--gray-light {
    @apply bg-sirius-gray-200;
  }
}
</style>
