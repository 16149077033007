import { WebApp } from '@shared/WebApp';
import { RahmetApp } from '@shared/RahmetApp';
import { DevConfigs } from '@/utils/dev-config';
import { $log } from '@/utils/plugins/logger';

export const GeoStatuses = ['granted', 'notDetermined', 'denied'];

/**
 * @typedef Location
 * @prop {number} latitude
 * @prop {number} longitude
 * @prop {number} accuracy
 *
 * Получаем локацию юзера
 * @returns {Promise<Location>}
 */
function getGeoLocation() {
  return new Promise((resolve, reject) => {
    const fn = window.RahmetApp?.getCachedGeoPosition;
    if (typeof fn === 'function') {
      $log('getCachedGeoPosition fn exists');
      const res = fn(JSON.stringify({ maximum_age: 5 * 60 * 1000 }));
      $log('getCachedGeoPosition res', res);
      if (res) {
        return resolve({
          latitude: res.latitude,
          longitude: res.longitude,
          timestamp: res.timestamp,
        });
      }
    }
    navigator.geolocation.getCurrentPosition(
      ({ coords }) => {
        resolve({
          latitude: coords.latitude,
          longitude: coords.longitude,
          accuracy: coords.accuracy,
        });
      },
      err => {
        reject(err);
      },
      {
        // В вебвью хрома на андроиде не учитывается
        enableHighAccuracy: true,
        // Если поставить маленькое значение, то если браузер не успел получить
        // его за этот промежуток, вернётся ошибка.
        // На тестовом устройстве время получения от 1 до 3 секунд.
        timeout: 5000,
        // Разрешаем 5 минутный кеш.
        // В итоге это нигде не работает:
        // на ios запрос гео идёт постоянно в фоне, поэтому работает быстро,
        // а андроид хром не учитывает опцию maximumAge
        // https://issues.chromium.org/issues/41346868
        maximumAge: 1000 * 60 * 10,
      }
    );
  });
}

/**
 * Делает запрос на разрешение сбора геолокации
 * @returns {Promise<String>}
 */
function requestPermission() {
  if (DevConfigs.isDev) {
    return WebApp.requestGeoPermissionStatus();
    // return new Promise(resolve => {
    //   setTimeout(resolve('granted'), 1000);
    // });
  }

  if (RahmetApp.isWebView()) return RahmetApp.requestGeoPermissionStatus();

  return WebApp.requestGeoPermissionStatus();
}

function requestGeoPermission() {
  return requestPermission()
    .then(res => res)
    .catch(() => 'denied');
}

/**
 * Возвращает информацию о локации юзера, или же ошибку
 *
 * @prop {number} latitude
 * @prop {number} longitude
 * @prop {number} accuracy
 *
 * @returns {Promise<Location|Error>}
 */
function getUserLocation() {
  return requestPermission().then(res => {
    if (res === 'granted' || res === 'notDetermined') return getGeoLocation();
    return Promise.reject(new Error('Geo denied'));
  });
}

export const GeoService = {
  requestGeoPermission,
  getUserLocation,
};
