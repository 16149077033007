<template>
  <div
    class="DeliveryContextDesk relative"
    :class="{ 'w-full': !!houseAddress }"
  >
    <!-- если у юзера есть сохранённый адрес -->
    <!-- v-else-if="houseAddress" -->
    <div class="delivery-context" @click="onDeliveryClick">
      <IconLocation class="w-6 h-6 mt-1" />
      <div class="ml-2">
        <div class="delivery-context-info">
          <p class="delivery-context-time">
            {{
              !address
                ? $t('deliveryFrom15')
                : $t('weWillDeliverFor', { time: expectedDeliveryTime })
            }}
          </p>
          <ExtendedDeliveryTooltip
            v-if="isDistant"
            class="delivery-context-distant"
            @click.native.stop
          />
        </div>
        <div class="delivery-context-address">
          {{ !address ? $t('provideDeliveryAddress') : houseAddress }}
        </div>
      </div>
    </div>

    <AddressListDesk v-if="showList" />

    <IsAddressCorrectContainer v-if="isAddressCorrectTooltipShown" />

    <NoAddressReminderContainer v-else />
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import { CityService } from '@services/city';
import { Analitycs, EVENTS } from '@shared/services/analitycs';
import { getAddressText } from '@services/address/lib/get-address-text';

import IconLocation from '../icons/IconLocation.vue';

export default {
  name: 'DeliveryContext',
  components: {
    IconLocation,
    IsAddressCorrectContainer: () =>
      import('@components/address/IsAddressCorrectContainer.vue'),
    NoAddressReminderContainer: () =>
      import('@components/address/NoAddressReminderContainer.vue'),
    AddressListDesk: () =>
      import('@components/address/AddressListDesk/AddressListDesk.vue'),
    ExtendedDeliveryTooltip: () =>
      import('@components/extended-delivery/ExtendedDeliveryTooltip.vue'),
  },
  inject: ['popup'],
  data: () => ({
    isAddressCorrectTooltipShown: false,
    showList: false,
  }),
  computed: {
    ...mapGetters('user', ['addressCount', 'addresses']),
    ...mapGetters('paidOrder', ['isDistant']),
    ...mapState('user', {
      address: state => state.deliveryAddress,
    }),
    ...mapGetters('delivery', ['expectedDeliveryTime']),
    houseAddress() {
      return (
        getAddressText(this.address, {
          lang: this.$i18n.locale,
          withName: true,
        }) || this.$t('selectAddress')
      );
    },
  },
  mounted() {
    if (this.$route.params.changeAddress) {
      this.openAddressList();
    }
  },
  methods: {
    openMap(from) {
      this.$eventBus.emit('eb_open_address_create_container', { from });
    },
    onDeliveryClick() {
      if (this.addressCount !== 0) this.openAddressList();
      else this.openMap('desk_header');
    },
    openAddressList(from = 'desk_header') {
      this.popup.show('address-list-toolpopup');
      this.showList = true;
      Analitycs.logEvent(EVENTS.VIEWED_ADDRESS, {
        address_list: this.addresses,
        from,
      });
    },
  },
  eventBusEvents: {
    eb_open_address_list(data) {
      if (this.$isDesktop) this.openAddressList(data.from);
    },
    eb_init_page_load_done() {
      // открывает тултип города если еще не выбран город
      if (!CityService.isDefaultCitySelected())
        this.isAddressCorrectTooltipShown = true;
    },
  },
  i18n: {
    messages: {
      ru: {
        deliveryFrom15: 'доставка от 15 минут',
        weWillDeliverFor: 'доставим за {time} мин',
        provideDeliveryAddress: 'Укажите адрес доставки',
        selectAddress: 'Укажите адрес',
        addressSelected: 'Адрес выбран основным',
      },
      kk: {
        deliveryFrom15: 'жеткізу 15 минуттан бастап',
        weWillDeliverFor: '{time} минутта жеткіземіз',
        provideDeliveryAddress: 'Тапсырыс мекен-жайын таңдаңыз',
        selectAddress: 'Мекен-жай таңдаңыз',
        addressSelected: 'Мекенжай басты болып таңдалды',
      },
    },
  },
};
</script>

<style scoped>
.delivery-context {
  @apply w-full 
    pt-1.5 pb-2 px-3 
    border border-sirius-orange-100 rounded-2xl 
    flex items-start cursor-pointer;

  min-width: 160px;
}
.delivery-context-info {
  @apply flex items-center;
}
.delivery-context-time {
  @apply whitespace-nowrap 
  text-sirius-orange-100 
  font-bold text-sm leading-4;
}
.delivery-context-distant {
  @apply flex items-center justify-center h-5 w-7;
}
.delivery-context-address {
  @apply mt-0.5 line-clamp-1 text-lg leading-5;
}
</style>
