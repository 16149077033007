<template>
  <div class="CartAddressContext cart-address-context">
    <div>
      <p
        class="cart-address-context-address"
        :class="{ 'cart-address-context-address__noDelivery': noDelivery }"
      >
        <slot name="address"></slot>
      </p>
      <p class="cart-address-context-time"><slot name="time"></slot></p>
    </div>
    <div class="cart-address-context-arrow"><IconRightArrow /></div>
  </div>
</template>

<script>
import IconRightArrow from './components/IconRightArrow.vue';
export default {
  components: { IconRightArrow },
  props: {
    noDelivery: Boolean,
  },
};
</script>

<style scoped>
.cart-address-context {
  @apply w-full flex items-center justify-between space-x-2;
}
.cart-address-context-address {
  @apply text-lg font-bold leading-6 line-clamp-1 break-all;
}
.cart-address-context-address__noDelivery {
  @apply text-sirius-gray-1000;
}
.cart-address-context-time {
  @apply mt-1 text-sirius-gray-1000 lowercase text-base font-medium leading-5;
}
.cart-address-context-arrow {
  @apply h-full;
}
</style>
