<template>
  <div class="OrderItem order" :class="{ active: active, 'is-link': isLink }">
    <div class="order-content-1">
      <p class="order-hash">{{ $t('order') }} № {{ order.code }}</p>
      <p class="order-paid">{{ paidAt }}</p>
      <p class="order-status" :class="status.color">
        {{ status.title[$i18n.locale] }}
      </p>
    </div>
    <div class="order-content-2">
      <p class="order-address">{{ address }}</p>
      <p class="order-price">
        <LoyaltyCount :count="loyaltyCount" class="order-loyalty" />
        {{ totalPrice }}
      </p>
    </div>
  </div>
</template>

<script>
import { formatPrice } from '@/utils/formatters/price';
import { dateAndTime } from '@/utils/formatters/date';
import { getStatusText } from '@/services/orders/utils/get-status-text';
import LoyaltyCount from './components/LoyaltyCount/LoyaltyCount.vue';

export default {
  name: 'OrderItem',
  components: { LoyaltyCount },
  props: {
    order: {
      type: Object,
      required: true,
    },
    active: {
      type: Boolean,
      default: false,
    },
    isLink: {
      type: Boolean,
      default: false,
    },
    loyaltyCount: {
      type: Number,
      default: 0,
    },
  },
  computed: {
    address() {
      let address = this.order.address;
      if (address) {
        const index = address.indexOf('Кв./офис');
        if (index !== -1) {
          address = address.substring(0, index).trim();
          address = address.slice(-1) === '.' ? address.slice(0, -1) : address;
        }
      }
      return this.order.street || address || 'Address not found';
    },
    paidAt() {
      return this.order.paid_at
        ? dateAndTime(this.order.paid_at, true, this.$i18n.locale) // eslint-disable-line no-undef
        : this.$t('orderHasNotBeenPaid');
    },
    status() {
      return getStatusText(this.order.status);
    },
    totalPrice() {
      let delivery = !this.order.free_delivery ? this.order.delivery_price : 0;
      return `${formatPrice(
        this.order.price + (delivery || 0) + (this.order.service_fee || 0)
      )} ₸`;
    },
  },
  methods: {
    formatPrice,
  },
  i18n: {
    messages: {
      ru: {
        order: 'Заказ',
        orderHasNotBeenPaid: 'Заказ не оплачен',
      },
      kk: {
        order: 'Тапсырыс',
        orderHasNotBeenPaid: 'Тапсырыс төленбеген',
      },
    },
  },
};
</script>

<style scoped>
/* .order {
  @apply ;
} */
.order.is-link {
  @apply hover:text-sirius-orange-100 cursor-pointer;
}
.order.active {
  @apply pt-3 px-3 pb-4
    md:pt-6 md:pr-6 md:pb-8 md:pl-7
  bg-white border-none rounded-2xl
    md:rounded-3xl;

  box-shadow:
    0px 8px 92px rgba(0, 0, 0, 0.03),
    0px 3px 38.4354px rgba(0, 0, 0, 0.04),
    0px -3px 20.5494px rgba(0, 0, 0, 0.03),
    0px 3.79px 11.5198px rgba(0, 0, 0, 0.005),
    0px 2.40368px 6.11809px rgba(0, 0, 0, 0.02),
    0px 0.705169px 2.54588px rgba(0, 0, 0, 0.01);

  &:hover {
    box-shadow:
      0px 3px 12px rgba(0, 0, 0, 0.05),
      0px 1px 12px rgba(0, 0, 0, 0.06),
      0px -1px 4px rgba(0, 0, 0, 0.03),
      0px 1px 2px rgba(0, 0, 0, 0.005),
      0px 1px 1px rgba(0, 0, 0, 0.02),
      0px 0.705169px 1px rgba(0, 0, 0, 0.01);
  }
  &:active {
    box-shadow:
      0px 3px 2px rgba(0, 0, 0, 0.05),
      0px 1px 4px rgba(0, 0, 0, 0.06),
      0px -1px 4px rgba(0, 0, 0, 0.03),
      0px 1px 0px rgba(0, 0, 0, 0.005),
      0px 1px 1px rgba(0, 0, 0, 0.02),
      0px 0.705169px 1px rgba(0, 0, 0, 0.01);
  }
}

.order-content-1 {
  @apply flex items-center
    text-xs leading-14 font-medium text-sirius-gray-100
    md:text-base;
}
/* .order-hash {
  @apply;
} */
.order-paid {
  @apply ml-3 md:ml-6;
}
.order-status {
  @apply ml-auto md:pb-2 md:pt-1.5 md:px-3 rounded-lg;
  padding: 3px 8px;
  max-height: 34px;
}
.order-content-2 {
  @apply mt-3 md:mt-4 flex justify-between items-center;
}
.order-address {
  @apply text-base font-medium leading-5
    md:text-2xl;
}
.order-price {
  @apply text-lg leading-6 font-bold
    md:text-2xl;
  align-items: center;
  display: flex;
}

.order-loyalty {
  margin-right: 8px;
}
</style>
