<template>
  <nav class="BottomBar bottom-bar">
    <ul class="flex">
      <BarElement
        v-for="(tab, index) in tabs"
        :key="index"
        :active="$route.name === tab.routeName"
        @click.native="onTabClick(tab, $route.name === tab.routeName)"
      >
        <template #icon>
          <component :is="tab.icon" />
        </template>
        <template #text> {{ $t(tab.name) }} </template>
      </BarElement>
    </ul>
  </nav>
</template>

<script>
import { RahmetApp } from '@shared/RahmetApp';
import { Analitycs, EVENTS } from '@shared/services/analitycs';
import IconTabBarMain from '@shared/components/icons/IconTabBarMain.vue';
import IconTabBarCatalog from '@shared/components/icons/IconTabBarCatalog.vue';
import IconTabBarProfile from '@shared/components/icons/IconTabBarProfile.vue';
import IconTabBarFavorites from '@shared/components/icons/IconTabBarFavorites.vue';
import BarElement from './BarElement.vue';

export default {
  name: 'BottomBar',
  components: {
    BarElement,
    IconTabBarMain,
    IconTabBarCatalog,
    IconTabBarProfile,
    IconTabBarFavorites,
  },
  data() {
    return {};
  },
  computed: {
    tabs() {
      const tabs = [
        {
          name: 'main',
          routeName: 'main',
          icon: IconTabBarMain,
          method: this.onMainClick,
        },
        {
          name: 'favorites',
          routeName: 'favorites',
          icon: IconTabBarFavorites,
          method: this.onFavoritesClick,
        },
        {
          name: 'profile',
          routeName: 'me',
          icon: IconTabBarProfile,
          method: this.onProfileClick,
        },
      ];

      if (this.$store.getters['showNewMainPageAndCatalog']) {
        tabs.splice(1, 0, {
          name: 'catalog',
          routeName: 'catalog',
          icon: IconTabBarCatalog,
          method: this.onCatalogClick,
        });
      }

      return tabs;
    },
  },
  methods: {
    onTabClick(tab, isActive) {
      RahmetApp.hapticSelection();
      if (isActive) return;

      tab.method();
    },
    onCatalogClick() {
      Analitycs.logEvent(EVENTS.CATALOG_OPENED, { from: 'bottom_tab_bar' });
      this.$router.push({ name: 'catalog' });
    },
    onMainClick() {
      this.$router.push({ name: 'main' });
    },
    onFavoritesClick() {
      this.$router.push({
        name: 'favorites',
        params: { from: 'Bottom Bar' },
      });
    },
    onProfileClick() {
      // todo аналитика перехода в профиль
      this.$router.push({ name: 'me' });
    },
  },
  i18n: {
    messages: {
      ru: {
        main: 'Главная',
        catalog: 'Каталог',
        favorites: 'Избранное',
        profile: 'Профиль',
      },
      kk: {
        main: 'Бастапқы',
        catalog: 'Каталог',
        favorites: 'Таңдаулы',
        profile: 'Профиль',
      },
    },
  },
};
</script>

<style scoped>
.bottom-bar {
  @apply w-full bg-white;
}
</style>
