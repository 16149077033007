<template>
  <div
    v-if="$store.getters['popularProducts/products'].length"
    class="PopularProducts"
  >
    <UiNavigationTitle class="px-4 md:px-0" main>
      {{
        $i18n.locale === 'ru' ? 'Выбор покупателей' : 'Тұтынушылардың таңдауы'
      }}
    </UiNavigationTitle>
    <div class="pp__container px-4 mt-3 pb-2 grid-rows-auto custom-grid gap-2">
      <ProductCardWrapper
        v-for="product in $store.getters['popularProducts/products']"
        :key="`${product.id}_${product.categoryId}`"
        :product="product"
        :added-from="PRODUCT_ADDED.POPULAR"
      />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import UiNavigationTitle from '@ui/UiNavigationTitle.vue';
import ProductCardWrapper from '@shared/components/product/ProductCardWrapper.vue';
import { PopularProductsService } from '@services/popular-products';
import { PRODUCT_ADDED } from '@shared/config/product';

export default {
  name: 'PopularProducts',
  components: {
    UiNavigationTitle,
    ProductCardWrapper,
  },
  data: () => ({ PRODUCT_ADDED }),
  computed: {
    ...mapGetters('abtest', ['testsObj']),
  },
  watch: {
    testsObj() {
      PopularProductsService.loadPopularToStore();
    },
  },
};
</script>

<style scoped>
.custom-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  justify-content: center;
}
@media (max-width: 359px) {
  .custom-grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }
}
</style>
