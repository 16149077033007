<template>
  <div class="profile-card">
    <div
      class="profile-card__card bg-sirius-gray-200 px-3 pt-5 pb-5 rounded-2xl"
    >
      <div class="profile-card__top flex items-center">
        <ProfilePicture :photo="user.photo" />
        <div class="pl-3">
          <div class="font-bold">
            {{ user.name || $t('noName') }}
          </div>
          <div v-if="user.phone" class="pt-1 text-sm">
            {{ formatPhoneNumber(user.phone) }}
          </div>
        </div>
        <LoyaltyBadge
          v-if="$store.getters['loyalty/available']"
          class="ml-auto"
          :count="$store.getters['loyalty/count']"
          @click.native="
            $eventBus.emit('eb_open_loyalty_info_modal', {
              count: $store.getters['loyalty/count'],
            })
          "
        />
      </div>
    </div>
  </div>
</template>

<script>
import ProfilePicture from './ProfilePicture';
import LoyaltyBadge from './LoyaltyBadge.vue';
import { formatPhoneNumber } from '@/utils/formatters/phone';

export default {
  name: 'ProfileCard',
  components: {
    ProfilePicture,
    LoyaltyBadge,
  },
  props: {
    user: {
      type: Object,
      default: () => ({}),
    },
  },
  methods: { formatPhoneNumber },
  i18n: {
    messages: {
      ru: {
        phoneNumber: 'номер телефона',
        noName: 'Имя не указано',
      },
      kk: {
        phoneNumber: 'телефон нөмірі',
        noName: 'Аты-жөні енгізілмеген',
      },
    },
  },
};
</script>
