<template>
  <div class="h-full">
    <div class="">
      <p class="text-white text-xl text-center">RESULTS:</p>
      <pre
        v-if="results"
        class="text-xxs"
        :class="{ 'text-sirius-red-200': results.isError }"
        >{{ results.res }}</pre
      >
    </div>

    <div class="mt-8 flex flex-col space-y-5">
      <UiButton
        v-for="(item, index) in methods"
        :key="index"
        class="px-5 py-3"
        variant="darkGray"
        @click="item.method"
      >
        {{ item.name }}
      </UiButton>
    </div>
  </div>
</template>

<script>
import { FileService } from '@services/file';
import { RahmetApp } from '@shared/RahmetApp';
import { mapGetters, mapMutations } from 'vuex';
import { TESTS } from '@services/abtest/tests';
import { ABTestService } from '@services/abtest';
import { AuthService } from '@shared/services/auth';
import { Logger as LOG } from '@shared/services/logger';
import { stringToMD5 } from '@/utils/functions/string-to-md5';
import { DeliveryConfigService } from '@services/delivery-config';
import { getNotificationStatus, requestNotificationStatus } from 'choco-app';
import UiButton from '../ui/UiButtonNew.vue';
import { store } from '@/store';

export default {
  name: 'MethodsView',
  components: {
    UiButton,
  },
  inject: ['bottomSheetPopup'],
  data() {
    return {
      results: null,
    };
  },
  computed: {
    ...mapGetters('abtest', ['getGroupByCode']),
    methods() {
      const that = this;

      return [
        {
          name: 'Переключить язык',
          method() {
            const lc = that.$root.$i18n.locale;
            that.$root.$i18n.locale = lc === 'kk' ? 'ru' : 'kk';
            that.$emit('close');
          },
        },
        {
          name: 'Поменять тестовую группу для лояльности',
          method() {
            store.commit(
              'loyalty/SET_AVAILABLE',
              !store.getters['loyalty/available']
            );
            ABTestService.toggleTestGroup('loyalty');
            that.$emit('close');
          },
        },
        {
          name: 'has getCachedGeoPosition',
          method() {
            const method = window.RahmetApp?.getCachedGeoPosition;
            const type = typeof method;

            that.results = {
              isError: type !== 'function',
              res: {
                'typeof window.RahmetApp.getCachedGeoPosition': type,
                'window.RahmetApp.getCachedGeoPosition()':
                  type === 'function'
                    ? method(JSON.stringify({ maximum_age: 5 * 60 * 1000 }))
                    : 'is not a function',
              },
            };
          },
        },
        {
          name: 'Поменять тестовую группу для популярных товаров',
          method() {
            ABTestService.toggleTestGroup('popular_products');
            that.$emit('close');
          },
        },
        {
          name: 'Поменять тест группу для новой главной',
          method() {
            ABTestService.toggleTestGroup('catalog_section');
            that.$emit('close');
          },
        },
        {
          name: 'has checkAuth',
          method() {
            const type = typeof window.RahmetApp.checkAuth;

            that.results = {
              isError: typeof window.RahmetApp.checkAuth !== 'function',
              res: {
                'typeof window.RahmetApp.checkAuth': type,
                'window.RahmetApp.checkAuth()':
                  type === 'function'
                    ? window.RahmetApp.checkAuth()
                    : 'is not a function',
              },
            };
          },
        },
        {
          name: 'Сбросить опрос',
          method() {
            window.localStorage.removeItem('questionnaire');
            that.$emit('close');
            RahmetApp.reloadPage();
          },
        },
        {
          name: 'Поменять тест группу для Корзины в Табе',
          method() {
            ABTestService.toggleTestGroup(TESTS.tabbarCart.code);
          },
        },
        {
          name: 'Поменять тест группу для плашки surge',
          method() {
            ABTestService.toggleTestGroup(TESTS.surgePlank.code);
          },
        },
        {
          name: 'hapticSelection',
          method() {
            RahmetApp.hapticSelection();
          },
        },
        {
          name: 'hapticNotification success',
          method() {
            RahmetApp.hapticNotification('success');
          },
        },
        {
          name: 'hapticNotification error',
          method() {
            RahmetApp.hapticNotification('error');
          },
        },
        {
          name: 'hapticNotification warning',
          method() {
            RahmetApp.hapticNotification('warning');
          },
        },
        {
          name: 'hapticImpact light',
          method() {
            RahmetApp.hapticImpact('light');
          },
        },
        {
          name: 'hapticImpact medium',
          method() {
            RahmetApp.hapticImpact('medium');
          },
        },
        {
          name: 'hapticImpact heavy',
          method() {
            RahmetApp.hapticImpact('heavy');
          },
        },
        {
          name: 'Toggle Product-review mocks',
          method() {
            let hasMocks = window.localStorage.getItem('product-review::mocks');
            if (hasMocks)
              window.localStorage.removeItem('product-review::mocks');
            else window.localStorage.setItem('product-review::mocks', true);

            if (RahmetApp.isWebView()) {
              RahmetApp.reloadPage();
            } else {
              document.location.reload();
            }
          },
        },
        {
          name: 'Включить промо модалку',
          method() {
            window.localStorage.removeItem('promo-modal-2024.03.06');
            if (RahmetApp.isWebView()) {
              RahmetApp.reloadPage();
            } else {
              document.location.reload();
            }
          },
        },
        {
          name: 'md5',
          method() {
            that.results = { res: stringToMD5('asdf'), isError: false };
          },
        },
        {
          name: 'Sentry Error',
          method() {
            throw new Error('Sentry Error');
          },
        },
        {
          name: 'Принудительно включить таблицу победителей новогоднего конкурса',
          method() {
            that.$store.commit('nyContest/SET_FORCE_SHOW_TABLE', true);
            that.$emit('close');
          },
        },
        {
          name: 'Принудительно включить FreeDeliveryPromocode',
          method() {
            that.$emit('close');
            DeliveryConfigService.freeDeliveryPromoMocks();
          },
        },
        {
          name: 'Принудительно включить обычную доставку',
          method() {
            that.$emit('close');
            DeliveryConfigService.defaultDeliveryMocks();
          },
        },
        {
          name: 'Принудительно включить повышку',
          method() {
            that.$emit('close');
            DeliveryConfigService.highDeliveryMocks();
          },
        },
        {
          name: 'Принудительно включить дальние заказы',
          method() {
            that.$emit('close');
            DeliveryConfigService.distantDeliveryMocks();
          },
        },
        {
          name: 'getNotificationStatus',
          method() {
            getNotificationStatus().then(res => {
              that.results = {
                res,
                isError: !res,
              };
            });
          },
        },
        {
          name: 'requestNotificationStatus',
          method() {
            requestNotificationStatus().then(res => {
              that.results = {
                res,
                isError: !res,
              };
            });
          },
        },
        {
          name: 'показать попап о рефералке',
          method() {
            that.$emit('close');
            that.$router.push({ name: 'main', query: { refprog: true } });
            document.location.reload();
          },
        },
        {
          name: 'принудительно включить рефералку',
          method() {
            window.localStorage.setItem('referral-force-enable');
            that.$emit('close');
          },
        },
        {
          name: 'вернуть плашку про рефералку',
          method() {
            window.localStorage.removeItem('referral-bottomsheet-hidden');
            that.$emit('close');
          },
        },
        {
          name: 'changeVersionPO',
          method: () => this.changePaidOrderBlockVersion(),
        },
        {
          name: 'RahmetApp.reloadPage()',
          method: () => {
            RahmetApp.reloadPage();
          },
        },
        {
          name: 'typeof window.RahmetApp.reloadPage',
          method: () => {
            const strTypeof = typeof window.RahmetApp.reloadPage;
            /* eslint-disable prefer-template */
            that.results = {
              res: 'typeof window.RahmetApp.reloadPage ' + strTypeof,
            };
            /* eslint-enable prefer-template */
          },
        },
        {
          name: 'show successpayment',
          method() {
            that.$emit('close');
            that.bottomSheetPopup.show('success-payment-popup');
          },
        },
        {
          name: 'remove questionnaire',
          method() {
            window.localStorage.removeItem('questionnaire-shown');
          },
        },
        {
          name: 'openLink()',
          method: () => this.openLink(),
        },
        {
          name: 'Expire access token',
          method: () => this.expireAccessToken(),
        },
        {
          name: 'Go To ABTest',
          method: () => this.goToABTest(),
        },
        {
          name: 'shareImage',
          method: () => this.share('shareImage'),
        },
        {
          name: 'shareText',
          method: () => this.share('shareText'),
        },
        {
          name: 'loadBase64File',
          method: () => this.share('loadBase64File'),
        },
        {
          name: 'Throw test error',
          method: () => {
            throw new Error('Test error');
          },
        },
        {
          name: 'Throw error inside promise',
          method: () => {
            // eslint-disable-next-line no-new
            new Promise(() => {
              throw new Error('Test unhandled promise rejection');
            });
          },
        },
      ];
    },
  },
  methods: {
    ...mapMutations('abtest', {
      changeGroupByCode: 'CHANGE_GROUP_BY_CODE',
    }),

    async share(type) {
      if (type === 'shareText') {
        RahmetApp.shareText('https://ryadom.kz/');
        LOG.event('RahmetApp.shareText(https://ryadom.kz/)');
      }

      if (type === 'shareImage') {
        const path = new URL('@img/logo sign.png', import.meta.url).href;
        const blob = await FileService.filePathToBlob(path);
        const mime = blob.type;
        const image = await FileService.fileToBase64(blob);
        LOG.event('RahmetApp.shareToInstagram(image, shareImage, mime)', {
          image,
          mime,
        });
        RahmetApp.shareToInstagram(image, 'shareImage', mime);
      }

      if (type === 'loadBase64File') {
        const path = new URL('@img/up-button.svg', import.meta.url).href;
        const blob = await FileService.filePathToBlob(path);
        const mime = blob.type;
        const image = await FileService.fileToBase64(blob);
        LOG.event('RahmetApp.shareFile(image, loadBase64File, mime)', {
          image,
          mime,
        });
        RahmetApp.shareFile(image, 'loadBase64File', mime);
      }
    },
    expireAccessToken() {
      AuthService.expireAccessToken();
    },
    goToABTest() {
      this.$emit('close');
      this.$router.push({ name: 'abtest' });
    },
    openLink() {
      const url = 'https://ryadom.kz';
      const result = RahmetApp.openLink({ url });
      this.results = {
        res: result,
        isError: !!result,
      };
    },
    changePaidOrderBlockVersion() {
      this.$emit('close');
      const codes = TESTS.paidDelivery.groups;
      const code = this.getGroupByCode(TESTS.paidDelivery.code);
      if (!code) return;
      const index = codes.findIndex(v => v === code);
      const newCode = codes[(index + 1) % codes.length];
      this.changeGroupByCode({
        code: TESTS.paidDelivery.code,
        group: newCode,
      });
    },
  },
};
</script>
