<template>
  <NotFoundProducts v-if="isEmpty" />
  <div v-else class="grid grid-cols-2 xs:grid-cols-3 grid-rows-auto gap-2">
    <ProductCardWrapper
      v-for="item in products"
      :key="`${item.id}_${item.category_id}`"
      :product="item"
      :added-from="addedFrom"
    />
    <OpenTimeContainer />
  </div>
</template>

<script>
import { PRODUCT_ADDED } from '@shared/config/product';
import NotFoundProducts from '@shared/components/product/NotFoundProducts.vue';
import ProductCardWrapper from '@shared/components/product/ProductCardWrapper.vue';
import OpenTimeContainer from '@components/open-time/OpenTimeContainer.vue';

export default {
  name: 'ProductsSearchResultCategory',
  components: {
    NotFoundProducts,
    ProductCardWrapper,
    OpenTimeContainer,
  },
  props: {
    products: {
      type: Array,
      default: () => [],
    },
    addedFrom: {
      type: String,
      default: PRODUCT_ADDED.CATEGORY_SEARCH,
    },
  },
  computed: {
    isEmpty() {
      return this.products.length === 0;
    },
  },
};
</script>
