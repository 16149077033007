<template>
  <MainLayout>
    <template v-if="$isWebView" #header>
      <ToolBar :back="onBackClick">
        {{ $t('myOrders') }}
      </ToolBar>
    </template>
    <ProfileLayout :container-width="644">
      <div
        class="px-4 md:px-0 text-sirius-black-100"
        :class="{ 'mt-4': $isWebView }"
      >
        <div v-if="loading" class="w-full flex justify-center mt-8">
          <UiRippleLoader transition="fade" class="mx-auto" />
        </div>

        <div
          v-else-if="!loading && !hasFinishedOrders && !activeOrders.length"
          class="mt-10 md:mt-0 mx-auto flex flex-col md:flex-row text-center md:text-left overflow-x-hidden"
        >
          <img
            class="hidden md:block mr-3"
            src="@img/cart/empty-cart.png"
            width="288px"
            height="282px"
            alt=""
          />
          <img
            class="md:hidden mr-3 object-contain"
            style="height: 168px; scale: 1.3"
            src="@img/cart/empty-cart.png"
            width="100%"
            height="168px"
            alt=""
          />
          <div class="mt-4 md:mt-9">
            <p class="font-bold text-2xl md:text-3xl">{{ $t('noOrders') }}</p>
            <p class="mt-4 md:mt-6Q text-lg">{{ $t('changeIt') }}</p>
            <router-link :to="{ name: 'main' }">
              <UiButton
                class="mt-8 md:mt-7 px-7 w-full md:w-min font-bold text-lg whitespace-nowrap"
                size="lg"
              >
                {{ $t('pickProducts') }}
              </UiButton>
            </router-link>
          </div>
        </div>

        <div v-else>
          <div class="max-width-588">
            <h1
              v-if="!$isWebView"
              class="mb-5 md:mb-9 font-bold text-2xl md:text-32 leading-tight"
            >
              {{ $t('myOrders') }}
            </h1>
          </div>
          <div v-if="activeOrders.length" class="mb-7 max-width-612">
            <p class="font-bold md:text-2xl max-width-588">
              {{ $t('active') }}
            </p>

            <div class="mt-4 md:mt-8">
              <OrderItem
                v-for="order in activeOrders"
                :key="order.id"
                class="order-item active"
                :order="order"
                active
                is-link
                @click.native="goToOrder(order.id)"
              />
            </div>

            <ProductsReviewWrapper
              v-if="hasReviewProducts"
              class="mt-4"
              screen="orders"
            />
          </div>

          <div v-if="hasFinishedOrders" class="max-width-588">
            <!-- style="max-width: 588px; margin:0 auto;" -->
            <h1 class="font-bold md:text-2xl">
              {{ $t('allOrders') }}
            </h1>
            <div class="mt-4 md:mt-2">
              <UiInfiniteScroll
                :is-loading="isNextPageLoading"
                @callback="fetchNextPage"
              >
                <OrderItem
                  v-for="order in finishedOrders"
                  :key="order.id"
                  class="order-item"
                  :order="order"
                  :loyalty-count="
                    $isWebView && $store.getters['loyalty/available']
                      ? getOrderLoyaltyCount(order)
                      : 0
                  "
                  is-link
                  @click.native="goToOrder(order.id)"
                />
              </UiInfiniteScroll>
            </div>
          </div>
        </div>
      </div>
    </ProfileLayout>
  </MainLayout>
</template>

<script>
import { mapGetters } from 'vuex';
import { RahmetApp } from '@shared/RahmetApp';
import { OrderService } from '@services/orders';
import { rFetchOrders } from '@shared/services/api/apis';
import { Analitycs, EVENTS } from '@shared/services/analitycs';

import ProductsReviewWrapper from '@components/product-review/ProductsReviewWrapper.vue';
import OrderItem from '@pure-ui/components/Orders/OrderItem/OrderItem.vue';
import ProfileLayout from '@shared/components/layout/ProfileLayout.vue';
import UiButton from '@shared/components/ui/UiButtonNew.vue';
import UiInfiniteScroll from '@ui/UiInfiniteScroll.vue';
import UiRippleLoader from '@ui/UiRippleLoader.vue';
import MainLayout from '@layout/MainLayout.vue';
import ToolBar from '@layout/ToolBar.vue';
import { getOrderLoyaltyCount } from '@/lib/loyalty/get-order-loyalty-count';

export default {
  name: 'OrdersView',
  components: {
    ProductsReviewWrapper,
    UiInfiniteScroll,
    UiRippleLoader,
    ProfileLayout,
    MainLayout,
    OrderItem,
    UiButton,
    ToolBar,
  },
  inject: ['toast'],
  data: () => ({
    finishedOrders: [],
    loading: false,
    isNextPageLoading: false,
    page: 1,
    maxPage: null,
    pageCache: {},
  }),
  computed: {
    ...mapGetters('productReview', ['hasReviewProducts']),
    ...mapGetters(['isAuthorized']),
    ...mapGetters('orders', ['activeOrders']),
    hasFinishedOrders() {
      return this.finishedOrders.length !== 0;
    },
    nextPage() {
      return this.page + 1;
    },
  },
  mounted() {
    Analitycs.logEvent(EVENTS.VIEWED_ORDERS);
    if (this.isAuthorized) this.initOrders();
  },
  methods: {
    getOrderLoyaltyCount,
    onBackClick() {
      this.$router.push({ name: 'main' });
    },
    initOrders() {
      this.loading = true;

      OrderService.fetchActiveOrders()
        .then(() => {
          return rFetchOrders({ tab: 'finished', is_paid: 1 });
        })
        .then(res => {
          if (!res) return;
          this.finishedOrders = res.data.items;
          this.updatePagination(res.data.pagination);
        })
        .catch(err => {
          this.toast.show(err.message);
        })
        .finally(() => (this.loading = false));
    },
    fetchNextPage() {
      const inCache = this.pageCache.hasOwnProperty(this.nextPage);

      if (!inCache && this.page < this.maxPage) {
        this.pageCache[this.nextPage] = true;
        this.isNextPageLoading = true;

        rFetchOrders({
          tab: 'finished',
          page: this.nextPage,
        })
          .then(res => {
            if (!res) return;
            this.finishedOrders.push(...res.data.items);
            this.updatePagination(res.data.pagination);
          })
          .finally(() => (this.isNextPageLoading = false));
      }
    },
    updatePagination(pagination) {
      this.page = Number(pagination.current_page);
      this.maxPage = pagination.total_pages;
    },
    goToOrder(id) {
      RahmetApp.hapticSelection();
      this.$router.push({
        name: 'order',
        params: { id: id.toString() },
      });
    },
  },
  i18n: {
    messages: {
      ru: {
        myOrders: 'Мои заказы',
        youHaventOrderedYet: 'Вы еще ничего <br /> не заказывали',
        timeToFixIt: 'Самое время это исправить! ✨',
        pickProducts: 'Выбрать товары',
        allOrders: 'Все заказы',
        active: 'Активные',
        noOrders: 'Заказов пока нет',
        changeIt: 'Самое время это исправить! ✨',
      },
      kk: {
        myOrders: 'Тапсырыстарым',
        youHaventOrderedYet: '',
        timeToFixIt: 'Оны түзетудің уақыты келді! ✨',
        pickProducts: 'Өнімдерді таңдау',
        allOrders: 'Барлық тапсырыстар',
        active: 'Белсенді',
        noOrders: 'Әзір тапсырыстар жоқ',
        changeIt: 'Оны өзгертудің ең қолайлы сәті келді! ✨',
      },
    },
  },
};
</script>

<style scoped>
.order-item {
  @apply mb-4 md:mb-0 pt-3 md:pt-6 pb-4 md:pb-8 border-b-2 border-sirius-gray-200;
}
.order-item.active {
  @apply mb-3 md:mb-6 last:mb-0;
}
.max-width-588 {
  max-width: 588px;
  margin-left: auto;
  margin-right: auto;
}
.max-width-612 {
  max-width: 612px;
  margin-left: auto;
  margin-right: auto;

  @media (min-width: 768px) {
    max-width: 636px;
  }
}
</style>
